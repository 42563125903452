<template>
  <div>
    <v-col cols="3">
      <v-btn
        block
        height="42px"
        color="#1db954"
        class="download-btn"
        @click="openModal('create')"
        style="color: black"
      >
        <v-icon>mdi mdi-plus-thick</v-icon>
        Neu
      </v-btn>
    </v-col>
    <v-data-table
      :key="roadSafetyUpdateKey"
      :headers="headers"
      :search="performSearch"
      :items="roadSafetys"
      :loading="isLoading"
      :footer-props="{
        'items-per-page-text': 'Zeilen pro Seite',
      }"
    >
      <template v-slot:item.actions="{ item }">
        <div class="button-container">
          <v-icon
            small
            class="edit green--text icon-style"
            @click="openModal('update', item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            class="delete green--text icon-style"
            @click="openDeleteModal('delete', item)"
          >
            mdi-delete
          </v-icon>
        </div>
      </template>
    </v-data-table>
    <create-road-safety
      :roadSafety="{ name: '', description: '' }"
      :visible="dialogType === 'create'"
      :id="null"
      @close="closeModal"
      @addItem="addItem"
      v-on:updated-table="updatedList"
    />
    <update-road-safety
      :key="roadSafetyUpdateKey"
      :roadSafety="selectedRoadSafety"
      :visible="dialogType === 'update'"
      :id="selectedRoadSafety.id"
      @close="closeModal"
      v-on:updated-table="updatedList"
      @updateItem="updateItem"
    >
    </update-road-safety>
    <delete-road-safety
      :visible="dialogType === 'delete'"
      :roadSafety="selectedRoadSafety"
      :item-list="itemList"
      v-on:updated-table="updatedList"
      @close="closeDeleteModal"
    ></delete-road-safety>
  </div>
</template>

<script>
import CreateRoadSafety from "./CreateRoadSafety";
import UpdateRoadSafety from "./UpdateRoadSafety";
import DeleteRoadSafety from "./DeleteRoadSafety";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    CreateRoadSafety,
    UpdateRoadSafety,
    DeleteRoadSafety,
  },
  data() {
    return {
      headers: [
        { text: "Abkürzung", value: "name" },
        { text: "Beschreibung", value: "description" },

        { text: "", value: "actions", sortable: false },
      ],
      itemList: [],
      roadSafetyUpdateKey: null,
      roadSafetyList: [],
      isLoading: false,
      dialogType: "",
      selectedRoadSafety: {},
      localSearch: null,
      showDeleteModal: false,
    };
  },
  computed: {
    ...mapGetters(["getSearchquery", "roadSafetys"]),

    performSearch() {
      return this.getSearchquery;
    },
  },
  mounted() {
    this.localSearch = this.getSearchquery;
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions(["fetchRoadSafetys", "createRoadSafety", "updateRoadSafety"]),
    editRoadSafety(roadSafety) {
      this.selectedRoadSafety = { ...roadSafety };
      this.dialogType = "update";
    },
    updatedList(boolean) {
      if (boolean) {
        this.fetchData();
      }
    },
    openModal(type, roadSafety) {
      this.dialogType = type;
      if (type === "update") {
        this.selectedRoadSafety = { ...roadSafety };
      }
    },
    openDeleteModal(type, roadSafety) {
      this.dialogType = type;
      if (type === "delete") {
        this.selectedRoadSafety = { ...roadSafety };
      }
    },
    closeModal() {
      this.dialogType = "";
      this.selectedRoadSafety = {};
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
      this.dialogType = "";
      this.selectedRoadSafety = {};
    },
    async addItem(newItem) {
      this.isLoading = true;
      await this.createRoadSafety(newItem);
      this.closeModal();
    },
    async updateItem(item) {
      this.isLoading = true;
      await this.updateRoadSafety(item);
      this.UpdateKey += 1;
      this.closeModal();
    },
    fetchData() {
      this.isLoading = true;
      this.fetchRoadSafetys()
        .then(() => {
          this.$store.commit("setRoadSafetyTypeResults", response.data);
          this.roadSafetys = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    handleSearch(query) {
      this.getSearchquery = query;
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-style {
  border: 1px solid #494949;
  padding: 3px;
  border-radius: 5px;
  width: 42px;
  height: 42px;
  margin-left: -60px !important;
}

.button-container {
  display: flex;
  align-items: center;
}
.button-container .delete {
  position: absolute;
  right: 15px;
}
.button-container .edit {
  position: absolute;
  right: 67px;
}
.add-button {
  outline: none !important;
  margin-bottom: 10px;
  width: 220px;
}
</style>
